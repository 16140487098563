import DropDownMenu_SeparateShinyIIMenuButtonSkinComponent from '@wix/thunderbolt-elements/src/components/DropDownMenu/viewer/skinComps/SeparateShinyIIMenuButton/SeparateShinyIIMenuButtonSkin.skin';
import DropDownMenu_SeparateShinyIIMenuButtonSkinController from '@wix/thunderbolt-elements/src/components/DropDownMenu/viewer/DropDownMenu.controller';


const DropDownMenu_SeparateShinyIIMenuButtonSkin = {
  component: DropDownMenu_SeparateShinyIIMenuButtonSkinComponent,
  controller: DropDownMenu_SeparateShinyIIMenuButtonSkinController
};


export const components = {
  ['DropDownMenu_SeparateShinyIIMenuButtonSkin']: DropDownMenu_SeparateShinyIIMenuButtonSkin
};

